import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {concatMap, mergeMap, tap} from "rxjs/operators";
import {concat, forkJoin, Observable, of} from "rxjs";
import {mutateState} from "../app.utils";
import {ChoiceService} from "./choice.service";
import {widgets} from "../state/record-page.state";

@Injectable({
  providedIn: 'root'
})
export class WidgetService extends BaseService {
  key = 'widgets'

  constructor(public injector: Injector) {
    super(injector)
  }


  retrieveObjects(params: object, propagations: string[] = []): any {
    return super.retrieveObjects(params, propagations).pipe(
      concatMap((observables$: any) => (observables$.length ? concat(...observables$) : of([]))),
      mergeMap((widget: any) => {
        return of(widget);
      }),
    );
  }


  retrieveObject(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: ['read_form', 'write_forms', 'inline_write_form', 'actions']};
    return super.retrieveObject(id, propagations, {...expand, ...params})
  }


}
