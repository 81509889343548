import {Directive, ElementRef, Output, Input, OnInit, AfterViewInit, inject, HostListener, EventEmitter} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, delay, distinctUntilChanged } from 'rxjs';
import * as _ from 'lodash';

import { waitForElm } from '../app.utils';
import { LocalService } from '../services/local.service';

@Directive({
  selector: '[prioritynav]'
})
export class PrioritynavDirective implements OnInit, AfterViewInit {
  
  @Input() recalculate: Observable<void>;
  @Output() hiddenItemsUpdated  = new EventEmitter<any>();

  readonly #takeUntilDestroyed = takeUntilDestroyed();
  
  private hiddenItems: any[] = [];

  private elementRef =  inject(ElementRef);
  private localService = inject(LocalService);
  constructor() {}
  
  ngOnInit(){
    this.recalculate?.pipe(this.#takeUntilDestroyed).subscribe(() => {
      this.calculateHiddenItems();
    })

    this.localService.siderVisibility$.pipe(delay(300), distinctUntilChanged(), this.#takeUntilDestroyed).subscribe(() => {
        this.calculateHiddenItems();
    })
  }

  ngAfterViewInit(): void {
    waitForElm('.prioritynav').then(elm => {
      this.calculateHiddenItems();
    })
  }

  @HostListener('window:resize')
  public onResize() {
    this.calculateHiddenItems();
  }

  calculateHiddenItems(){
    if(this.elementRef){
      let hiddenItems = [];
      
      let moreButtonWidth = 0;
      const moreBtn = this.elementRef.nativeElement.querySelector('.' + PrioritynavSelectors.MORE_BTN);
      if(moreBtn){
          moreBtn.hidden = false;
          moreButtonWidth = moreBtn.clientWidth;
      }

      const navItems = this.elementRef.nativeElement.querySelectorAll('.' + PrioritynavSelectors.ITEM);
      _.forEach(navItems, (item: HTMLElement) => { item.hidden = false;});
      const activeItem = _.head(_.filter(navItems, (item: HTMLElement) => this.isActiveItem(item))) as HTMLElement;
      const extraItemsWidth = this.getExtraElementsWidth();
      let visibleWidth = moreButtonWidth + extraItemsWidth + (activeItem?.clientWidth ?? 0);

      _.forEach(navItems, (item: HTMLElement) => {
          if(visibleWidth + item.clientWidth >= (this.elementRef.nativeElement.clientWidth - 2)){
            if (!this.isActiveItem(item)) {
                item.hidden = true;
                hiddenItems.push(this.createHiddenItem(item));
            }
          }else{
            if (!this.isActiveItem(item)) {
              visibleWidth += item.clientWidth;
              item.hidden = false;
            }
          }
          moreBtn.querySelector('.' + PrioritynavSelectors.MORE_COUNT).innerText = `+${hiddenItems.length}`;
      });

      if(hiddenItems.length == 0) moreBtn.hidden = true;

      hiddenItems = this.orderByPosition(hiddenItems)
      this.updateHiddenMenu(hiddenItems);
      this.hiddenItems = hiddenItems;
      this.hiddenItemsUpdated.emit(hiddenItems)
    }
  }

  createHiddenItem(item: HTMLElement): HTMLElement{
    const menuItem = document.createElement('div');
    menuItem.classList.add('menu-item');
    
    const link = item.querySelector('.' + PrioritynavSelectors.LINK) as HTMLElement;
    const clonedLink = link.cloneNode(true) as HTMLElement;
    clonedLink.setAttribute('href', "#");
    clonedLink.classList.add('menu-link');
    clonedLink.dataset.position = link.dataset.position;

    if(clonedLink.classList.contains('nav-link')) {
      clonedLink.classList.remove('nav-link');
    }

    menuItem.appendChild(clonedLink);
    
    clonedLink.addEventListener('click', evt => {
      evt.preventDefault();
      link.click();
    })
    
    return menuItem;
  }

  isActiveItem(item: HTMLElement): boolean {
    return item.querySelector('.' + PrioritynavSelectors.LINK).classList.contains('active');
  }

  getExtraElementsWidth(): number{
    const extraItems = this.elementRef.nativeElement.querySelectorAll("." + PrioritynavSelectors.EXTRA_ELEMENT);
    return _.sum(_.map(extraItems, item => item.clientWidth));
  }

  updateHiddenMenu(hiddenItems: any[]){
    const hiddenMenu = this.elementRef.nativeElement.querySelector('.' + PrioritynavSelectors.HIDDEN_MENU);
      hiddenMenu.innerHTML = '';
      _.forEach(hiddenItems, item => hiddenMenu.appendChild(item));
  }

  orderByPosition(hiddenItems){
    return hiddenItems = _.orderBy(hiddenItems, item => item.querySelector('.' + PrioritynavSelectors.LINK).dataset.position)
  }
}

export enum PrioritynavSelectors{
  ITEM = 'prioritynav-item',
  LINK = 'prioritynav-link',
  MORE_BTN = 'prioritynav-more-btn',
  MORE_COUNT = 'prioritynav-more-count',
  HIDDEN_MENU = 'prioritynav-hidden-menu',
  EXTRA_ELEMENT = 'prioritynav-extra-element'
}