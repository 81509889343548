import {Injectable,NgZone} from '@angular/core';
import {Injector} from '@angular/core';
import {BaseService} from './base.service';
import {LocalService} from "./local.service";
import {of, throwError} from "rxjs";
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {Store} from '@ngxs/store';
import {ActivatedRoute, Router} from '@angular/router';
import {Inject} from '@angular/core';
import {Cache} from '../classes/cache'
import { WebSocketService } from './websocket.service';
import {TranslocoService} from '@jsverse/transloco'
import {GtmService} from "./gtm.service";
import { getDefaultProfilePicture } from '../app.utils';
declare var $: any;

export enum Level {
  None = 0,
  Read = 1,
  Write = 2,
  Administrate = 3,
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  key = 'users';

  constructor(public injector: Injector,
              public localService: LocalService,
              @Inject('Cache') private cache: Cache,
              public store: Store,
              private route: ActivatedRoute,
              private router: Router,
              private gtmService: GtmService,
              private webSocketService: WebSocketService,
              private translocoService: TranslocoService,
              ) {
    super(injector);

  }

  login(email, password, keepMeConnected) {
    return this.http.post(this.envService.apiEndpoint + 'login/', {email: email, password: password, keepMeConnected : keepMeConnected}).pipe(
      map((res: any) => {
        const currentTimestamp = new Date().toISOString();
        this.localService.setLastLogin(currentTimestamp);
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  checkUserSso(email) {
    return this.http.get(this.envService.apiEndpoint + 'connected_user/',{params : {'email': email}} );
  }

  logout() {
    this.gtmService.stopOpenReplay();
    return this.http.post(this.envService.apiEndpoint + 'logout/', {});
  }

  sendReset(email) {
    return this.http.post(this.envService.apiEndpoint + 'sendreset/', { email: email });
  }

  reset(email, token, password, confirmation, firstName, lastName) {
    return this.http.post(this.envService.apiEndpoint + 'reset/', {
      email: email,
      token: token,
      password: password,
      confirmation: confirmation,
      firstName: firstName,
      lastName: lastName
    });
  }

  connectedUser() {
    const token = this.localService.getUser();
    return this.http.get(this.envService.apiEndpoint + 'connected_user/').pipe(
      mergeMap((res: any) => {
        if (!res['user']) return throwError(() => new Error(`Invalid user`));
        this.translocoService.setActiveLang(res['language'])
        this.localService.setLanguage(res['language']);
        if (Number.isNaN(this.localService.getSiderVisibility())){
          this.localService.setSiderVisibility(1);
        }
        if (!token || token!=res['user']) {
          this.localService.setUser(res['user']);
          this.localService.setSpace(res['space']);
          try {
            this.webSocketService.connectUserWS();
          } catch (exception) {
          }
        }
        return this.retrieveObject(res['user'], ['space']);
      }),
      mergeMap((user: any) => {
        this.gtmService.sendToIntercom(user);
        this.gtmService.initSentry(user);
        this.gtmService.startOpenReplay(user);
        return of(true);
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  public getExcelUrl() {
    const url = this.envService.apiEndpoint + this.key + '/?';
    const qp = {};
    qp['extension'] = 'xlsx';
    qp['space'] = this.localService.getSpace();
    const queryString = Object.keys(qp).map(key => {
      if (Array.isArray(qp[key])) {
        return qp[key].map(val => key + '=' + val).join('&');
      }
      return key + '=' + qp[key];
    }).join('&');
    return url + queryString;
  }

  can(ability: string, subject = null) {
    return this.store.select(state => state.app.sider).pipe(
      map((sider: any) => {
        if(sider.user) {
          // return Boolean(true)
          switch (ability) {
            case 'CreateRecord':
            case 'CreateChildrenRecords':
            case 'CreateCheckpoints':
              return subject.accessLevel >= Level.Write  // entity or parentRecord
            case 'ExecuteAction':
            case 'UpdateMainField':
            case 'UpdateField':
            case 'UpdateRecordTags':
            case 'UpdateCheckpointUnclickable':
            case 'UpdateCheckpointHidden':
            case 'UpdateWidgetHidden':
            case 'DeleteFieldPicture':
              return subject.accessLevel >= Level.Write;
            case 'RemoveRecord':
              return subject.accessLevel >= Level.Administrate;
            case 'AccessAdministrationLink':
              return _.some(sider.user.currentRoles.map(r => r.canAdministrateUser)) || sider.pages.findIndex(p => (p.entity?.accessLevel >= Level.Administrate || p.record?.entity.accessLevel >= Level.Administrate)) > -1;
            case 'AccessAdministrationSection':
              if (subject.id === 0) {
                return _.some(sider.user.currentRoles.map(r => r.canAdministrateUser)) && !sider.user.space.parent;
              } else {
                return subject.accessLevel >= Level.Administrate
              }
            case 'FeedDatalake':
                if (subject.id === -1) {
                  return _.some(sider.user.currentRoles.map(r => r.canFeedDatalake));
                } else {
                  return false
                }
            case 'UpdateOrDeleteCommentOrAttachment':
              return subject.author.id == sider.user.id || _.some(sider.user.currentRoles.map(r => r.canAdministrateUser))
            default: throw new Error('Bad ability key: ' + ability);
          }
        }
        else {
          return false;
        }
      })
    )
  }

  public transform(object){
    if (!object.picture){
      object.picture = getDefaultProfilePicture(object);
    }
    return object;
  }
}
