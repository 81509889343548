import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'orderByVisible'
})
export class OrderByVisiblePipe implements PipeTransform {

  transform(objects: any[], hiddenItems: any[], property: string): any {
    if (!hiddenItems) {
      return objects
    }

    if (hiddenItems.length === objects.length) {
      return objects
    };

    const visibleObjects = _.filter(objects, obj => !_.includes(hiddenItems, obj[property]));
    const hiddenObjects = _.filter(objects, obj => _.includes(hiddenItems, obj[property]))

    return [...visibleObjects, ...hiddenObjects]
  }

}
