import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {imageIconMapping} from '../../app.utils';
import * as _ from 'lodash'

@Component({
  selector: 'attachments',
  templateUrl: './attachments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsComponent implements OnInit, OnChanges {

  @Input() attachments;
  @Input() parentScroll;
  @Input() isReady;
  @Input() isDataLake = false;
  @Input() public total;
  @Input() public isSiderBlock;

  @Output() public loadMoreBtnClick = new EventEmitter();

  public readonly PAGE_SIZE = 10;
  public fetching = false;
  public ready = false;
  public page = 1;

  @Output() attachmentCreate = new EventEmitter<any>();
  @Output() attachmentDestroy = new EventEmitter<any>();
  @Output() attachmentScroll = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  getGalleryNumber(attachments) {
    let pictures = 0;
    _.map(attachments, a => a.file && _.keys(imageIconMapping).includes(a.file.split('.')[a.file.split('.').length - 1].toLowerCase()) && pictures++)
    return pictures;
  }

  onAttachmentCreate(file) {
    this.attachmentCreate.emit(file);
  }

  onAttachmentDestroy(id) {
    this.attachmentDestroy.emit(id);
  }

  onPictureDelete({src, parent}) {
    const attachment = _.find(this.attachments, a => a.file === src)
    this.attachmentDestroy.emit(attachment.id);
  }

  track(index: number, obj): number {
    return obj.id;
  }

  ngOnChanges(changes: any): void {
    if (!this.ready){
      if (changes.attachments && changes.attachments.currentValue.length || this.total === 0){
        this.ready = true;
      }
    }
    if (this.fetching){
      this.fetching = false;
    }
  }

  public onLoadMoreClick(){
    this.page++;
    this.fetching = true;
    this.loadMoreBtnClick.emit({pageSize: this.PAGE_SIZE, page: this.page})
  }

  getRemainingCount() {
    return this.total - this.attachments.length;
  }

}
