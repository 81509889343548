import {Action, State, Store} from '@ngxs/store';
import {Header, HomePage} from './app.actions';
import {ViewService} from '../services/view.service';
import {LocalService} from '../services/local.service';
import {delayWhen, map, mergeMap, tap,catchError} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {RecordService} from '../services/record.service';
import * as _ from 'lodash';
import {UserService} from '../services/user.service';
import {computePageSize, getMode, groupRecords, hasGroupby, mutateState} from '../app.utils';
import {EntityService} from '../services/entity.service';
import {Injectable} from '@angular/core';
import { param } from 'cypress/types/jquery';

@State({
  name: 'homePage',
  defaults: {
    views: null,
    records: {},
    currentPage: 1,
    unscheduledRecords: {},
    unscheduledCount: null,
    count: null,
    isUnscheduledReady: true,
    calendarStart: null,
    calendarEnd: null,
    user: null,
    isListReady: false,
    entity: null,
    currentView: null,
    latestResult: null,
    queryParams: {},
    allElementsLoaded: false,
  }
})
@Injectable()
export class HomePageState {

  constructor(private localService: LocalService,
              private userService: UserService,
              private viewService: ViewService,
              private entityService: EntityService,
              private recordService: RecordService,
              private store: Store) { }

  @Action(HomePage.Init, { cancelUncompleted: true })
  init(ctx, {queryParams, ready = false}) {
    return this.userService.retrieveObject(this.localService.getUser(), ['space']).pipe(
      mergeMap((user: any) => {
        let viewId = user.saves.home ? user.saves.home[this.localService.getSpace()] : null;
        mutateState(ctx, draft => {
          draft.user = user;
          if (!draft.currentView || draft.currentView.id !== +viewId) {
            draft.views = null;
            draft.entity = null;
          }
          if(!draft.currentView || draft.currentView.id !== +viewId || !_.isEqual(draft.queryParams, queryParams)){
            draft.currentView = null;
            draft.isListReady = false;
            draft.records = {};
          }
          draft.queryParams = queryParams;
        });
        let views$ = this.viewService.retrieveObjects({space: this.localService.getSpace(), user: this.localService.getUser()}).pipe(
            delayWhen((views: any) => {
              let observables = []
              views.forEach((view) => {
                const entity$ =  this.entityService.retrieveHomeMetadata(view.entity).pipe(
                  mergeMap((entity: any)=> {
                    view.entity = entity;
                    let qp = _.cloneDeep(view.value);
                    qp['entity'] = view.entity.id;
                    return this.recordService.retrieveObjects(qp).pipe(tap((records$: any) => {
                      view.count = records$.length
                    }));
                  })
                );
                observables.push(entity$);
              })
              return observables.length ? forkJoin(observables) : of([])
            })
          );
        return forkJoin([views$]).pipe(
          mergeMap(([views]: any) => {
            mutateState(ctx, draft => {
              draft.views = _.chain(views).groupBy('entity.plural').value();
            });
            if (viewId) {
              let view = views.find(v => v.id == viewId);
              if (view) {
                return this.store.dispatch(new HomePage.RetrieveRecords(view, queryParams, 1, ready));
              }
            } else {
              mutateState(ctx, draft => {
                draft.records = {};
                draft.isListReady = true;
              });
            }
            return of([])
          })
        );
      })
    );
  }

  @Action(HomePage.UploadPicture)
  uploadPicture(ctx, { file }) {
    return this.userService.updateObject({ id: this.localService.getUser(), picture: file }, ['space']).pipe(tap((user: any) => {
      this.store.dispatch(new Header.UpdateUserPicture(user.picture));
      mutateState(ctx, draft => {
        draft.user = user;
      });
    }));
  }

  SaveUserView(ctx, currentView) {
    // saving selected view to user "saves"
    try {

      let userSaves = _.cloneDeep(ctx.getState().user.saves);
      const spaceId = this.localService.getSpace();
      userSaves.home[spaceId] = currentView.id;

      mutateState(ctx, draft => {
        draft.user.saves.home[spaceId] = currentView.id;
      });
      return this.userService.updateObject({
        id: this.localService.getUser(),
        saves: userSaves
      });
    } catch (err) {
      return this.userService.updateObject({
        id: this.localService.getUser(),
        saves: { home: {}, records: {}, record: {} }
      });
    }
  }

  @Action(HomePage.RetrieveRecords, { cancelUncompleted: true })
  retrieveRecords(ctx, { currentView, queryParams, page, ready = false }) {
    const mode = getMode(currentView.value['mode']);
    const entityId = currentView.entity.id
    
    mutateState(ctx, draft => {
      draft.isListReady = (page !== 1 && (mode === 'cards' || mode === 'timeline')) || ready || currentView.id === draft.currentView?.id;
    
      if (page === 1 && !ready && currentView.id !== draft.currentView?.id) {       
        draft.currentPage = 1;
        draft.records = {};
        draft.records[currentView.id] = [];
      }
      if (page > 1 && !ready){
        draft.currentPage = page       
      }
      draft.currentView = currentView;
      draft.count = draft.currentView.count;
    });

    const qp = {..._.cloneDeep(currentView.value), ...queryParams, 'entity': entityId};
    
    let entity$ = this.entityService.retrieveHomeMetadata(entityId);
    return entity$.pipe(
      mergeMap((entity: any) => {
        mutateState(ctx, draft => {
          draft.entity = entity;
        });

        const options = {
          'page': page,
          'currentPage': ready ? ctx.getState().currentPage: 1,
          'calendarStart': ctx.getState().calendarStart,
          'calendarEnd': ctx.getState().calendarEnd
        };

        if (mode === 'calendar') {
          return this.recordService.retrieveCalenderRecords(qp, options['calendarStart'], options['calendarEnd'], () => {
            return entity.ownerField + ',';
          });
        } 
        if (mode === 'dashboard') {
          return this.recordService.retrieveDashboardRecords(qp);
        }          
        if (mode === 'timeline') {
          let pageSize =  computePageSize(12, 75);
          return this.recordService.retrieveTimelineRecords(qp, ['space'], pageSize, options.page, (r) => {
            return !qp['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded || hasGroupby(entity, f)).map(f => f.id).join(',');
          }) 
        }
        let pageSize = computePageSize(entity.cols, entity.height + 8)
        return this.recordService.retrieveCardsRecords(qp, ['space'], pageSize, options.page, (r) => {
          return !qp['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded).map(f => f.id).join(',') :
            _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded || hasGroupby(entity, f)).map(f => f.id).join(',');
        })
      }),
      mergeMap((records: any) => {
        mutateState(ctx, draft => {
          if (ready){
            draft.records[currentView.id] = []
          }
          if (['dashboard', 'calendar'].includes(mode)) {
            draft.records[currentView.id] = records;
          } else {
            const distinctRecords = _.uniqBy(_.concat(draft.records[currentView.id], records), (r: any) => r.id);
            draft.records[currentView.id] = !qp['groupby'] ? distinctRecords : groupRecords(draft.entity, distinctRecords, qp);
          }
          draft.allElementsLoaded = draft.records[currentView.id].filter((el) => el.id > -1).length == draft.count;
          draft.isListReady = true;
        });

        // unscheduled count
        const unscheduledCountQp = _.cloneDeep(currentView.value);
        const endingDateField = currentView.entity.endingDateField;
        const startingDateField = currentView.entity.startingDateField;
        
        if (endingDateField) unscheduledCountQp[endingDateField.name] = null;
        if (startingDateField) unscheduledCountQp[startingDateField.name] = null;
        unscheduledCountQp['entity'] = entityId;

        this.recordService.retrieveObjects(unscheduledCountQp).pipe(
          map((records$: any) => {
            mutateState(ctx, draft => {
              draft.unscheduledCount = records$.length;
            });
          })
        ).subscribe()

        return this.SaveUserView(ctx, currentView);
      })
    );
  }

  @Action(HomePage.ChangeCalendarDates)
  changeCalendarDates(ctx, { startDate, endDate, queryParams }) {
    mutateState(ctx, draft => {
      draft.calendarStart = startDate;
      draft.calendarEnd = endDate;
    });
    return this.store.dispatch(new HomePage.RetrieveRecords(ctx.getState().currentView, queryParams, 1));
  }

  @Action(HomePage.ClearView)
  clearView(ctx, { viewId }) {
    if (ctx.getState().currentView && ctx.getState().currentView.id === viewId) {
      this.SaveUserView(ctx, null).pipe(
        mergeMap((user: any) => {
        mutateState(ctx, draft => {
          draft.user.saves.home = user.saves.home;
          draft.currentView = null;
          draft.records = {};
          draft.entity = null;
        });
        return this.store.dispatch(new HomePage.Init({}));
      }));
    }
    else {
      this.store.dispatch(new HomePage.Init({}));
    }
  }

  @Action(HomePage.UpdateRecordFields)
  updateRecordFields(ctx, { record, fieldValueMapping }) {
    let newValue = {};
    Object.keys(fieldValueMapping).forEach((key) => {
      newValue[key] = fieldValueMapping[key];
    });
    return this.recordService.updateObject({ id: record.id, value: newValue }, ['value', 'tags', 'fixedCheckpoint']);
  }

  @Action(HomePage.RetrieveUnscheduledRecords)
  retrieveUnscheduledRecords(ctx, { currentView, page }) {
    mutateState(ctx, draft => {
      draft.isUnscheduledReady = false;
      if (page === 1) {
        draft.unscheduledRecords[currentView.id] = [];
      }
    });
    let qp = _.cloneDeep(currentView.value);
    const entity = currentView.entity;
    const UnscheduledCalenderRecords$ = this.recordService.retrieveUnscheduledRecords(qp, entity, page);
    return UnscheduledCalenderRecords$.pipe(tap((records: any) => {
        mutateState(ctx, draft => {
          if (draft.unscheduledRecords[currentView.id]) {
            draft.unscheduledRecords[currentView.id] = draft.unscheduledRecords[currentView.id].concat(records);
          } else {
            draft.unscheduledRecords[currentView.id] = records;
          }
          draft.unscheduledRecords [currentView.id] = _.uniqBy(draft.unscheduledRecords [currentView.id], (r) => {
            return r['id'.toString()];
          });
          draft.isUnscheduledReady = true;
        });
      }));
  }

  @Action(HomePage.RecordDrag)
  RecordDrag(ctx, {data}) {
    const entity = this.store.snapshot().app.homePage.entity;
    let currentIndex, targetIndex, currentRecord;
    const dateFieldId = data.timeline ? entity.startingDateField.id : entity.endingDateField.id;
    targetIndex = data.timeline ? data.index : 0;
    mutateState(ctx, draft => {
      if (data.newDate && ! data.record.value[entity.startingDateField.id]) {
        currentIndex = _.findIndex(ctx.getState().unscheduledRecords[ctx.getState().currentView.id], (r: any) => r.id === data.record.id);
        currentRecord = _.nth(ctx.getState().unscheduledRecords[ctx.getState().currentView.id], currentIndex);
        const updatedRecord = _.cloneDeep(currentRecord);
        _.remove(draft.unscheduledRecords[ctx.getState().currentView.id], (r: any) => r.id === data.record.id);
        draft.unscheduledCount--;
        draft.count++;
        if (data.timeline) {
          draft.records[ctx.getState().currentView.id] = [...draft.records[ctx.getState().currentView.id].slice(0, data.index), updatedRecord, ...draft.records[ctx.getState().currentView.id].slice(data.index)]
        } else {
          draft.records[ctx.getState().currentView.id].unshift(updatedRecord)
        }
      }
      // move from calendar to unscheduled
      if (!data.newDate) {
        currentIndex = _.findIndex(ctx.getState().records[ctx.getState().currentView.id], (r: any) => r.id === data.record.id);
        currentRecord = _.nth(ctx.getState().records[ctx.getState().currentView.id], currentIndex);
        draft.unscheduledRecords[ctx.getState().currentView.id].unshift(data.record);
        _.remove(draft.records[ctx.getState().currentView.id], (r: any) => r.id ===  data.record.id);
        draft.unscheduledCount++;
        draft.count--;
      }
    });
    let fieldValueMapping = {
      [dateFieldId]: data.newDate,
      [data.groupByField]: data.groupByValue
    }
    let newValue = {};
    Object.keys(fieldValueMapping).forEach((key) => {
      newValue[key] = fieldValueMapping[key];
    });
    return this.recordService.updateObject({
      id: data.record.id,
      value: newValue
    }, ['value', 'tags', 'fixedCheckpoint', 'originalCheckpointsets']).pipe(
      tap((record: any) => {
          mutateState(ctx, draft => {
            let records = draft.records
            if (data.newDate){
              records[ctx.getState().currentView.id] = _.map(records[ctx.getState().currentView.id], (r: any) => r.id === record.id ? record : r);
            } else {
              draft.unscheduledRecords[ctx.getState().currentView.id] = _.map(draft.unscheduledRecords[ctx.getState().currentView.id], (r: any) => r.id === record.id ? record : r);
            }
          });
        }
      ),
      catchError((error) => {
        mutateState(ctx, draft => {
          let records = draft.records
          if (data.newDate && !data.record.value[entity.startingDateField.id]) {
            draft.count--;
            draft.unscheduledCount++;
            draft.unscheduledRecords[ctx.getState().currentView.id] = [...draft.unscheduledRecords[ctx.getState().currentView.id].slice(0, currentIndex), currentRecord, ...draft.unscheduledRecords[ctx.getState().currentView.id].slice(currentIndex)]
            _.pullAt(records[ctx.getState().currentView.id], targetIndex);
          }
          // rollback from unscheduled to calendar
          if (!data.newDate){
            draft.count++;
            draft.unscheduledCount--;
            records[ctx.getState().currentView.id] = [...records[ctx.getState().currentView.id].slice(0, currentIndex), currentRecord, ...records[ctx.getState().currentView.id].slice(currentIndex)]
            _.remove(draft.unscheduledRecords[ctx.getState().currentView.id], (r: any) => r.id ===  data.record.id);
          }
        });
        throw error;
      })
    )
  }
}
