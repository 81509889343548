import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { BaseService } from './base.service';
import {forkJoin, mergeMap, of} from "rxjs";
import * as _ from "lodash";
import {fieldTypeRecord} from "../app.utils";


@Injectable({
  providedIn: 'root'
})
export class EntityService extends BaseService{
  key = 'entities'
  constructor(public injector:Injector) {
    super(injector);
  }

  public retrieveHomeMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks,starting_date_field,ending_date_field'};
    return super.retrieveObject(id, propagations, {...expand, ...params});
  }

  public retrieveAdministrationMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks,jobs,signals,tags,actions,extensions'};
    return super.retrieveObject(id, propagations, {...expand, ...params});
  }

  public retrieveListingMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks,actions,starting_date_field,main_field,ending_date_field,creation_form,tags,extensions,page,page.pageset'};

    return super.retrieveObject(id, propagations, {...expand, ...params}).pipe(
      mergeMap((entity: any) => {
        const fields = _.filter(_.flatten(_.map(entity.blocks, 'fields')), f => fieldTypeRecord.includes(f.type) && !!f.isExpanded);
        const mirrorEntities$ = _.map(fields, f => this.retrieveFieldManyMetadata(f.mirrorFields[0].entity));
        return (mirrorEntities$.length ? forkJoin(mirrorEntities$).pipe(mergeMap((entities: any) => {
          entity.mirrorEntities = entities;
          return of(entity);
        })) : of(entity));
      })
    );
  }

  public retrieveModalListingMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks'};
    return super.retrieveObject(id, propagations, {...expand, ...params}).pipe(
      mergeMap((entity: any) => {
        const fields = _.filter(_.flatten(_.map(entity.blocks, 'fields')), f => fieldTypeRecord.includes(f.type) && !!f.isExpanded);
        const mirrorEntities$ = _.map(fields, f => this.retrieveFieldManyMetadata(f.mirrorFields[0].entity));
        return (mirrorEntities$.length ? forkJoin(mirrorEntities$).pipe(mergeMap((entities: any) => {
          entity.mirrorEntities = entities;
          return of(entity);
        })) : of(entity));
      })
    );
  }

  public retrieveDetailMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks,tags,actions,main_field,page,extensions,dasboard_read_form,page,page.pageset'};
    return super.retrieveObject(id, propagations, {...expand, ...params});
  }

  public retrieveFieldManyMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks,starting_date_field,ending_date_field,creation_form,main_field'};
    return super.retrieveObject(id, propagations, {...expand, ...params});
  }

  public retrieveAuditMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks'};
    return super.retrieveObject(id, propagations, {...expand, ...params});
  }
}
