import { HttpClient } from '@angular/common/http';
import {
  TranslocoLoader,
  TranslocoModule,
  provideTransloco
} from '@jsverse/transloco';
import { Injectable, NgModule, Injector } from '@angular/core';
import { environment } from '../environments/environment';
import { EnvService } from './services/env.service';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import * as _  from "lodash";

export const availableLangs  = ['en', 'fr', 'cn'];
export function defaultLang(storage) {
  const lang = storage.getItem('lang');
  if(lang && availableLangs.includes(lang)) return lang;
  return 'fr';
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly checksum = this.getBuildHash();
  public http = null;
  public envService = null;
  constructor(public injector: Injector) {
    this.http = this.injector.get(HttpClient);
    this.envService = this.injector.get(EnvService);
  }

  getTranslation(lang: string) {
    if (!availableLangs.includes(lang)){
      lang = 'fr';
    }
    const parts = window && window.location && window.location.href.split("/");
    const scheme = parts[0];
    const host = parts[2];
    const path = `${scheme}//${host}/assets/i18n/${lang}.json`;
    return this.http.get(environment.production ? `${path}?v=${this.checksum}` : path);
  }

  private getBuildHash() {
    const scripts = document.getElementsByTagName('script');
    const src = _.find(scripts, (s: any)=> s.src.match(/main\.(.+)\.js/))?.src;
    if (src){
      return  src.match(/main\.(.+)\.js/)[1];
    }
    return '';
  }
}

@NgModule({
  exports: [ TranslocoModule ],

  providers: [
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        fr: 'fr-FR',
        cn: 'cn-CN'
      }
    }),
    provideTransloco({
      config: {
        availableLangs: availableLangs,
        defaultLang: defaultLang(localStorage),
        fallbackLang: "fr",
        reRenderOnLangChange: true,
        prodMode: environment.production
      },
      loader: TranslocoHttpLoader
    }),
  ]
})
export class TranslocoRootModule {}
