// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  font-size: 1.25rem !important;
}

.map-card {
  padding: 0;
  border-top-right-radius: 0.95rem;
  border-top-left-radius: 0.95rem;
}

.void {
  z-index: -100;
  visibility: hidden;
}

.modal-full-screen {
  max-width: 95%;
}

canvas {
  max-width: 100vw !important;
  max-height: 90vh !important;
}

#dynamic-form-container {
  position: absolute;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 400px;
  background-color: var(--bs-app-light-sidebar-bg-color);
}`, "",{"version":3,"sources":["webpack://./src/app/components/widget/widget.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACI,UAAA;EACA,gCAAA;EACA,+BAAA;AACJ;;AAEA;EACE,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,2BAAA;EACA,2BAAA;AACF;;AAEA;EACE,kBAAA;EACA,2CAAA;EACA,YAAA;EACA,YAAA;EACA,sDAAA;AACF","sourcesContent":[".table {\n    font-size: 1.25rem !important;\n}\n\n.map-card {\n    padding: 0;\n    border-top-right-radius: 0.95rem;\n    border-top-left-radius: 0.95rem\n}\n\n.void {\n  z-index: -100;\n  visibility: hidden;\n}\n\n.modal-full-screen {\n  max-width: 95%;\n}\n\ncanvas {\n  max-width: 100vw !important;\n  max-height: 90vh !important;\n}\n\n#dynamic-form-container{\n  position: absolute;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  z-index: 999;\n  width: 400px;\n  background-color: var(--bs-app-light-sidebar-bg-color);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
